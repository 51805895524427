.changeFramework {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  .pp-section.active &{
    opacity: 100%;

  }
}

.changeFramework__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 40px 35px;

  @include respond-above(m_plus) {
    flex-direction: row;
    padding: 150px 85px 50px 200px;
  }
}

// SPLIDE

.changeFramework__splide {
  width: 100%;

  .splide__slide,
  .splide__track {
    height: 100%;
  }

  // ARROWS

  .splide__arrows {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include respond-above(m_plus){
      left: 0;
      transform: translateX(0);
    }
    @include respond-iphone{
      bottom: 350px;
    }
  }

  .splide__arrow {
    display: block;
    position: relative;
    width: 22px;
  }

  .splide__arrow--prev {
    left: 0;
    margin-right: 140px;
    @include respond-iphone{
      left:-80px;
    }
  }

  .splide__arrow--next {
    right: 0;
    @include respond-iphone{
      right:-80px;
    }
  }

  // PAGINATIONS - BULLETS

  .splide__pagination {
    width: max-content;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    counter-reset: pagination-num;

    @include respond-above(m_plus){
      left: 0;
      transform: translateX(0);
    }
  }

  

  .splide__pagination__page {
    height: 0;
    padding-right: 20px;

    &.is-active {
      transform: scale(1);
      &::before {
        background-color: #000;
        color: $white
      }
    }
  }

  .splide__pagination__page:before {
    counter-increment: pagination-num;
    content: counter(pagination-num);
    @include fontOscarLight;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $black;
    border-radius: 100%;
    width: 20px;
    height: 20px;
  }
}