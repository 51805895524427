// menu style

.menu {
  display: block;
  position: relative;
}

.menu__hamburger {
  display: block;
  width: 40px;
  height: 20px;
  position: relative;
  background: transparent;
  cursor: pointer;
  border: 0;
  transform: rotate(0deg);
  transition: $transition-ease-in-out;
  z-index: 10;

  span {
    .header--white & {
      background-color: $white;
    }

    .header--black & {
      background-color: $black;
    }

    .contentWhyGalapagos & {
      background-color: #FFF;
    }

    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: $transition-ease-in-out;
  }

  span:nth-child(1),
  span:nth-child(2) {
    top: 1px;
  }


  span:nth-child(3) {
    top: 9px;
  }

  span:nth-child(4),
  span:nth-child(5) {
    top: 17px;
  }

  .menu--open & {
    transition: $transition-ease-in-out;

    span:nth-child(1) {
      transform-origin: top left;
      transform: rotate(52deg);
    }

    span:nth-child(2) {
      transform-origin: top right;
      transform: rotate(-52deg);
    }

    span:nth-child(3) {
      transition: $transition-ease-in-out;
      top: 18px;
      opacity: 0;
      width: 0%;
      left: 50%;
    }

    span:nth-child(4) {
      transform-origin: top left;
      top: 120%;
      transform: rotate(-52deg);
    }

    span:nth-child(5) {
      transform-origin: top right;
      top: 120%;
      transform: rotate(52deg);
    }
  }
}

.menu__content {
  position: absolute;
  width: 100vw;
  height: 100vh;
  right: 0;
  top: -40px;
  transition: $transition-ease-in-out;
  transform: translateX(calc(100% + 50px));

  @include respond-above(m_plus) {
    top: 50px;
    width: max-content;
  }

  .menu--open & {
    transform: translateX(40px);

    .contentWhyGalapagos & {
      background-color: $overlay-black;
    }

    .header--black & {
      background-color: $overlay-white;
    }

    .header--white & {
      background-color: $overlay-black;
    }

    @include respond-above(m_plus) {
      background-color: transparent !important;
      transform: translateX(0);
    }
  }
}

.menu__list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @include respond-above(m_plus) {
    justify-content: flex-end;
    align-items: flex-end;
    height: auto;
    border: 1px solid black;
    padding: 10px;
    transition: $transition-ease-in-out;

    .contentWhyGalapagos & {
      background-color: $black;
      border: 1px solid #FFF;
    }

    .header--white & {
      border: 1px solid $white;
    }

    .header--black & {
      border: 1px solid $black;
    }
  }

  // COLORS OF MENU

  [index="1"] & {
    @include respond-above(m_plus) {
      background-color: transparent;
      border: none;
    }

  }

  [index="2"] & {
    @include respond-above(m_plus) {
      background-color: $pink;
    }

  }

  [index="3"] & {
    @include respond-above(m_plus) {
      background-color: $white;
    }
  }

  [index="4"] & {
    @include respond-above(m_plus) {
      background-color: $white;
    }
  }

  [index="5"] & {
    @include respond-above(m_plus) {

      background-color: $turquese;
    }
  }

  [index="6"] & {
    @include respond-above(m_plus) {
      background-color:$white ;
    }
  }

  [index="7"] & {
    @include respond-above(m_plus) {
      background-color:$green;
    }
  }

  [index="8"] & {
    @include respond-above(m_plus) {
      background-color: $orange;
    }
  }

  [index="9"] & {
    @include respond-above(m_plus) {
      background-color: $pink;
    }
  }
}

.menu__text {
  transition: $transition-ease-in-out;
  @include fontOscarRegular;
  font-size: 28px;
  line-height: 44px;
  color: transparent;

  @include respond-above(m_plus) {
    font-size: 18px;
    line-height: 24px;
  }

  .contentWhyGalapagos & {
    color: #FFF;
  }


  .menu--open & {
    .header--white & {
      color: $white;
    }

    .header--black & {
      color: $black;
    }
  }

  &:hover {
    transform: scale(1.1);
  }
}
