//–––––––––––––––––––––
//  REM CONVERTER
//–––––––––––––––––––––

$text-base-size: 16px;

@function rem($size) {
  @return #{$size / $text-base-size}rem;
}

//–––––––––––––––––––––
//  RESPOND ABOVE
//–––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//–––––––––––––––––––––
//  RESPOND BELOW
//–––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//–––––––––––––––––––––
//  RESPOND BEETWEEN
//–––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

//IPHONE 

@mixin respond-iphone {
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    @content;
}
}


//–––––––––––––––––––––
//  EXPANDED ABSOLUTE
//–––––––––––––––––––––

@mixin expanded-abs {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//–––––––––––––––––––––
//  EXPANDED FIXED
//–––––––––––––––––––––

@mixin expanded-fix {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//–––––––––––––––––––––
//  COMPONENT SPACERS
//–––––––––––––––––––––

$resolution-range-bottom: map-get($breakpoints, xs);
$resolution-range-top: map-get($breakpoints, xxl);

@function get-linear-size($mobile-size, $desktop-size) {
  $a: ($desktop-size - $mobile-size) /
    ($resolution-range-top - $resolution-range-bottom);
  $b: -$a * $resolution-range-bottom;
  @return calc(#{$a} * 100vw + #{$b + $mobile-size});
}

@mixin v-spacing($field, $mobile-size, $desktop-size) {
  #{$field}: get-linear-size($mobile-size, $desktop-size);
  @include respond-above(xxl) {
    #{$field}: $desktop-size;
  }
  @include respond-below(s) {
    #{$field}: $mobile-size;
  }
}

// Example to include margin-bottom @include v-spacing(margin-bottom, 40px, 100px);

//–––––––––––––––––––––
//  PADDING WRAPPER
//–––––––––––––––––––––

$p-wp-mobile: 20px;
$p-wp-tablet: 40px;
$p-wp-desktop: 55px;

@mixin padding-wrapper {
  padding-left: $p-wp-mobile;
  padding-right: $p-wp-mobile;
  @include respond-above(s) {
    padding-left: $p-wp-tablet;
    padding-right: $p-wp-tablet;
  }
  @include respond-above(l) {
    padding-left: $p-wp-desktop;
    padding-right: $p-wp-desktop;
  }
}

@mixin viewport-height($property: height, $vh: 100) {
  #{$property}: (1vh * $vh);
  #{$property}: calc(var(--vh, 1vh) * #{$vh});
}

//–––––––––––––––––––––
//  IMAGE CROP
//–––––––––––––––––––––

@mixin allow-crop {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  &.focusOnTop {
    top: 0;
    transform: translate(-50%, 0%);
  }
  &.focusOnBottom {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
  }
}

//–––––––––––––––––––––
//  SECTION
//–––––––––––––––––––––

@mixin sectionBox {
  padding-left: 204px;
}

//–––––––––––––––––––––
//  MAX WIDTH
//–––––––––––––––––––––

@mixin max-width {
  display: block;
  position: relative;
  max-width: rem(1440px);
  width: 100%;
  margin: 0 auto;
}

//–––––––––––––––––––––
//  INNER WIDTH
//–––––––––––––––––––––

@mixin inner-width {
  width: calc(100% - 90px);
  margin: 0 auto;
  text-align: center;
  @include respond-above(s) {
    width: calc(100% - 72px);
  }
  @include respond-above(m_plus) {
    width: 80%;
  }
  @include respond-above(l) {
    width: 75%;
  }
}

//–––––––––––––––––––––
//  HIDDEN SCROLLBAR
//–––––––––––––––––––––

@mixin hidden-scrollbar {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

//–––––––––––––––––––––
//  PADDING & MARGIN BLOCK
//–––––––––––––––––––––

@mixin padding-block {
  padding: $dimension-mobile;
  @include respond-above(s) {
    padding: $dimension-tablet;
  }
  @include respond-above(m_plus) {
    padding: $dimension-desktop;
  }
}
@mixin padding-block-product {
  padding: $dimension-mobile;
  @include respond-above(s) {
    padding: $dimension-tablet;
  }
  @include respond-above(m_plus) {
    padding: 0 100px;
  }
}

@mixin padding-block-half {
  padding: $dimension-mobile / 2;
  @include respond-above(s) {
    padding: $dimension-tablet / 2;
  }
  @include respond-above(m_plus) {
    padding: $dimension-desktop / 2;
  }
}

@mixin padding-block-quarter {
  padding: $dimension-mobile / 4;
  @include respond-above(s) {
    padding: $dimension-tablet / 4;
  }
  @include respond-above(m_plus) {
    padding: $dimension-desktop / 4;
  }
}

@mixin margin-block {
  margin: $dimension-mobile;
  @include respond-above(s) {
    margin: $dimension-tablet;
  }
  @include respond-above(m_plus) {
    margin: $dimension-desktop;
  }
}

@mixin margin-block-half {
  margin: $dimension-mobile / 2;
  @include respond-above(s) {
    margin: $dimension-tablet / 2;
  }
  @include respond-above(m_plus) {
    margin: $dimension-desktop / 2;
  }
}
@mixin margin-block-bottom {
  margin-bottom: $dimension-mobile / 2;
  @include respond-above(s) {
    margin-bottom: $dimension-tablet / 2;
  }
  @include respond-above(m_plus) {
    margin-bottom: $dimension-desktop / 2;
  }
}

//THEME COLOR
@mixin theme-color {
  .section--standard &{
    &.j-light{
        color: white;
    }
    &.j-dark{
        color: black;
    }
}
}

@mixin theme-lines-color{
  .section--standard &{
    &.j-light{
        background-color: white;

    }
    &.j-dark{
        background-color: black;
    }
}
}
