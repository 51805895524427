// header style

.header {
  display: block;
  position: relative;
  z-index: 100; // Because the sections obtained a inline style z-index inserted from pagePiling. If there are 10 sections the sections have 10 z-index, from 1 to 10
}

.header__logoBig {
  display: none;

  [index="2"] &,
  [index="3"] &,
  [index="4"] &,
  [index="5"] &,
  [index="6"] &,
  [index="7"] &,
  [index="8"] &,
  [index="9"] & {
    transform: translateY(-120px);
  }

  @include respond-above(m_plus) {
    display: block;
    position: fixed;
    transition: $transition-ease-in-out;
    top: 30px;
    left: 40px;
    transform: translateY(0px);
  }

  .contentWhyGalapagos & {
    display: none;
  }
}

.header__logoSmall {
  display: none;

  @include respond-above(m_plus) {
    display: block;
    position: fixed;
    transition: $transition-ease-in-out;
    top: 30px;
    right: 110px;
    transform: translateY(-80px);
  }

  [index="2"] &,
  [index="3"] &,
  [index="4"] &,
  [index="5"] &,
  [index="6"] &,
  [index="7"] &,
  [index="8"] &,
  [index="9"] & {
    transform: translateY(0px);
  }

  .contentWhyGalapagos & {
    transform: translateY(0px);
  }
}

.header__section {
  
  .contentWhyGalapagos & {
    display: none;
  }

  .header__sectionText {

    @include fontOscarLight;
    font-size: 21px;
    line-height: 21px;
    text-transform: uppercase;

    @include respond-above(m_plus) {
      font-size: 21px;
      line-height: 21px;
    }

    [index="5"] & {
      color: $white;
    }

    span {
      display: block;
      position: fixed;
      top: 30px;
      left: 35px;
      opacity: 0;
      transform: translateX(-100px);
      transition: $transition-ease-in-out;

      @include respond-above(m_plus) {
        top: 40px;
        left: 120px;
      }
    }

    [index="1"] & {
      :nth-child(1) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="2"] & {
      :nth-child(2) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="3"] & {
      :nth-child(3) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="4"] & {
      :nth-child(4) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="5"] & {
      :nth-child(5) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="6"] & {
      :nth-child(6) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="7"] & {
      :nth-child(7) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="8"] & {
      :nth-child(8) {
        opacity: 1;
        transform: translateX(0px);
      }
    }

    [index="9"] & {
      :nth-child(9) {
        opacity: 1;
        transform: translateX(0px);
      }
    }
  }
}

.header__menu {
  display: block;
  position: fixed;
  top: 40px;
  right: 40px;
}

.header__arrow {
  display: none;
 
  .contentWhyGalapagos & {
    display: none;
  }

  @include respond-above(m_plus) {
    display: block;
    position: fixed;
    transition: $transition-ease-in-out;
    height: 85vh;
    top: 50%;
    left: 25px;
    transform: translate(-90px, -50%);

    [index="2"] &,
    [index="3"] &,
    [index="4"] &,
    [index="5"] &,
    [index="6"] &,
    [index="7"] &,
    [index="8"] &{
      transform: translate(0px, -50%);
    }
  }

}
