//–––––––––––––––––––––
//  BREAKPOINTS
//–––––––––––––––––––––

$breakpoints: (xxs_minus: 374px,
  xxs: 375px,
  xxs_plus: 376px,
  xs_minus: 479px,
  xs: 480px,
  xs_plus: 481px,
  s_minus: 767px,
  s: 768px,
  s_plus: 769px,
  m_minus: 1023px,
  m: 1024px,
  m_plus: 1025px,
  l_minus: 1279px,
  l: 1280px,
  l_plus: 1281px,
  xl_minus: 1439px,
  xl: 1440px,
  xl_plus: 1441px,
  xxl_minus: 1599px,
  xxl: 1600px,
  xxl_plus: 1601px);

//–––––––––––––––––––––
//  TRANSITIONS
//–––––––––––––––––––––

$curve-page-piling: cubic-bezier(0.550, 0.085, 0.000, 0.990);

$time-transition: 0.6s;
$time-transition-page-piling: 1s;

$transition-ease: all $time-transition ease;
$transition-ease-in-out: all $time-transition ease-in-out;
$transition-page-piling: all $time-transition-page-piling $curve-page-piling;

//–––––––––––––––––––––
//  SPACERS
//–––––––––––––––––––––

$spacers-xxs: 0.25rem; // 4px
$spacers-xs: 0.5rem; // 8px
$spacers-s: 1rem; // 16px
$spacers-m: 1.5rem; // 24px
$spacers-l: 2rem; // 32px
$spacers-xl: 2.5rem; // 40px
$spacers-xxl: 3.5rem; // 56px
$spacers-z: 4.5rem; // 72px
$spacers-xz: 5.5rem; // 88px
$spacers-xxz: 6.5rem; // 104px

//–––––––––––––––––––––
//  DEFAULT COLORS
//–––––––––––––––––––––

$red: #A10A0A;
$white: #F8F4EA;
$black: #000000;
$grey: #888B8D;

//–––––––––––––––––––––
//  OVERLAY COLORS
//–––––––––––––––––––––

$overlay-black: rgba(0, 0, 0, 0.9);
$overlay-white: rgba(248, 244, 234, 0.9);

//–––––––––––––––––––––
//  GENERAL COLORS
//–––––––––––––––––––––

$color-whatsapp: #25D366;

//–––––––––––––––––––––
//  GALAPAGOS COLORS
//–––––––––––––––––––––

$pink: #E8B7D5;
$green: #838723;
$turquese: #11715A;
$orange: #E85F2E;

//–––––––––––––––––––––
//  COLOR NOTIFICATIONS
//–––––––––––––––––––––

$notification-blue: #0c8ac0;
$notification-green: #0CC078;
$notification-yellow: #FFE662;
$notification-red: #FF6961;

//–––––––––––––––––––––
//  DIMENSION RULES
//–––––––––––––––––––––

$dimension-mobile: 12px;
$dimension-tablet: 26px;
$dimension-desktop: 20px;
$dimension-icon: 24px;

//–––––––––––––––––––––
//  BORDER SIZE
//–––––––––––––––––––––

$border-size: 1px;
$border-size-negative: -1.5px;
$border-size-negative-one: -1px;
