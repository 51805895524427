.contact {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.contact__wrapper {
  display: flex;
  width: 100%;
  height: 80%;
  flex-direction: column;

  @include respond-above(m_plus) {
    flex-direction: row;
    height: 80%;
  }
}

// BLOCK TEXT

.contact__wrapperText {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  padding: 70px 35px 20px 35px;

  @include respond-above(m_plus) {
    width: 50%;
    padding: 100px 80px 20px;
  }
}

.contact__textFirst,
.contact__textSecond {
  width: 100%;
  text-align: left;
  font-size: 36px;
  line-height: 28px;

  .contact__text {
    @include fontMDNichrome;
    text-transform: uppercase;

    @include respond-above(m_plus) {
      font-size: 7vw;
      line-height: 6vw;
    }
  }
}

// BLOCK FORM

.contact__wrapperForm {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 35px 65px 35px;

  @include respond-above(m_plus) {
    width: 50%;
    padding: 100px 80px 20px;
  }
  @include respond-iphone{
    padding: 10px 35px 150px 35px;
  }
}

.contact__logo {
  width: 136px;
  margin-bottom: 20px;

  @include respond-above(m_plus) {
    width: 295px;
    margin-bottom: 50px;
  }
}

.contact__form {
  width: 100%;
}

.contact__formSubmit {
  form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
}

.contact__input,
.contact__textarea {
  width: 100%;
  background-color: transparent;
  @include fontOscarLight;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 12px;
  margin-bottom: 10px;
  border: 2px solid $black;
  border-radius: 4px;

  @include respond-above(m_plus) {
    padding: 13px;
    font-size: 21px;
    line-height: 21px;
  }
}


.contact__submit {
  background: $black;
  border: 5px solid $black;
  border-radius: 4px;
  @include fontOscarLight;
  color: $white;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  cursor: pointer;

  @include respond-above(m_plus) {
    padding: 13px;
    font-size: 21px;
    line-height: 21px;
  }
}

.contact__img{
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.contac__img--i20{
  width: 100px;
  margin-bottom: 20px;

  @include respond-iphone{
    width: 150px;
    margin-bottom: 20px;
  }

  @include respond-above(m_plus) {
    width: 180px;
    margin-bottom: 20px;
  }
}