.changeFramework__content {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column-reverse;

  @include respond-above(m_plus){
    flex-direction: row;
  }
}

// LEFT

.changeFramework__blockLeft {
  display: block;
  position: relative;
  text-align: left;
  width: 100%;
  height: calc(50vh - 35px);

  @include respond-above(m_plus){
    width: 50%;
    height: 100%;
  }
  @include respond-iphone{
    padding: 0 20px;
  }
}

.changeFramework__section {
  @include fontOscarLight;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 52px;
  display: none;
  
  @include respond-above(m_plus){
    display: block
  }
}

.changeFramework__title {
  @include fontMDNichrome;
  text-transform: uppercase;
  font-size: 28px;
  line-height: 28px;
  margin-bottom: 30px;

  @include respond-above(m_plus){
    font-size: 4vw;
    line-height: 3.8vw;
  }
}

.changeFramework__description {
  @include fontOscarLight;
  font-size: 16px;
  line-height: 16px;

  @include respond-above(m_plus){
    font-size: 24px;
  line-height: 28px;
  }
}

// RIGHT

.changeFramework__blockRight {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: calc(50vh - 35px);

  @include respond-above(m_plus){
    width: 50%;
    height: 100%;
  }
}

.changeFramework__image {
  width: auto;
  padding: 0 50px;
  margin: 0 auto;

  @include respond-above(m_plus){
    width: 100%;
  }
}
