.footerNoBtn__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: transparent;
    padding: 40px 0px 30px;
    

    @include respond-above(s) {
        padding: 40px;
    }

    @include respond-above(m) {
        position: relative;
        display: block;
        height: auto;
        padding: 60px 80px;
    }
}



.footerNoBtn__content--black {
    padding: 60px 40px;

    * {
        color: $black;
    }
}

.footerNoBtn__content--white {

    * {
        color: $white;
    }
}

.footerNoBtn__separator {
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;

    @include respond-above(s) {
        margin-bottom: 40px;
        margin-top: auto;
    }

    @include respond-above(m) {
        display: block;
        margin-bottom: 20px;
    }

    .footerNoBtn__content--black & {
        background: $black;
    }

    .footerNoBtn__content--white & {
        background: $white;
    }
}

.footerNoBtn__block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;

    @include respond-above(m) {
        justify-content: space-between;
        flex-direction: row;
    }
}

.footerNoBtn__block__left {
    display: block;
    position: relative;
    text-align: left;
}

.footerNoBtn__block__right {
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 12px;

    @include respond-above(m) {
        text-align: right;
        margin-bottom: 0;
    }
    @include respond-iphone{
        margin-bottom: 15px;
    }
}

.footerNoBtn__block__left--firts{
    & .footerNoBtn__text {
        @include fontOscarRegular;
        font-size: 12px;
        margin-bottom: 0;
    
        @include respond-above(m) {
            font-size: 15px;
        }
        @include respond-iphone{
            font-size: 15px;
        }
    }
}

.footerNoBtn__text {
    @include fontOscarRegular;
    font-size: 12px;
    margin-bottom: 4px;

    @include respond-above(m) {
        font-size: 18px;
    }
    @include respond-iphone{
        font-size: 15px;
        margin-bottom: 7px;
    }
}

.footerNoBtn__block__link {
    display: flex;
    justify-content: flex-start;

    @include respond-above(m) {
        justify-content: flex-end;
    }
}

.footerNoBtn__link {
    @include fontOscarRegular;
    font-size: 16px;
    margin-bottom: 4px;
    cursor: pointer;

    @include respond-above(m) {
        font-size: 18px;
    }
    @include respond-iphone{
        font-size: 18px;
        margin-bottom: 7px;
    }
}

.footerNoBtn__block__center{
    @include fontOscarRegular;
    display: block;
    margin-bottom: 4px;

    @include respond-above(m) {
        display: block;
        font-size: 18px;
    }

    @include respond-iphone{
        font-size: 18px;
        margin-bottom: 7px;
    }

}

.footerNoBtn__svg{
    position: relative;
    width: 20px;
    margin: 0 10px;
}

.footerNoBtn__svg--white{
    display: none;
    .footerNoBtn__content--white &{
        display: block;
    }
}

.footerNoBtn__svg--black{
    display: none;
    .footerNoBtn__content--black &{
        display: block;
    }
}
