.home {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.home__wrapper {
  padding-top: 90px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  @include respond-iphone{
    padding-top: 50px;
    padding-bottom: 10px;
  }
}

.home__text {
  display: block;
  padding: 0 20px 40px;
  @include fontOscarRegular;
  width: 100%;
  color: $white;
  font-size: 32px;
  line-height: 32px;
  z-index: 1;

  @include respond-above(m_plus) {
    font-size: 80px;
    line-height: 75px;
    padding: 0 100px;
  }
}

.home__img {
  display: block;
  position: absolute;
  object-fit: cover;
  height: 100vh;
  width: 100vw;
}

.home__arrow {
  display: block;
  z-index: 1;
  height: 30vh;

  @include respond-above(m_plus) {
    display: none;
  }
}

.home__logo {
  display: block;
  width: 200px;
  z-index: 1;
  margin: 0 auto;
  margin-bottom: 20px;

  @include respond-above(m_plus) {
    display: none;
  }
}