.whyGalapagos {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  background-color: $black;
  color: #FFF;

  @include respond-above(m_plus) {
    width: 100vw;
    //height: 100vh;
  }
}

.whyGalapagos__wrapper {
  display: block;
  width: 100%;
  height: 100%;
  padding: 80px 35px 0px;

  @include respond-above(m_plus) {
    padding: 60px 80px 0px;
  }

  @include respond-above(l) {
    padding: 80px 100px 0px;
  }

  @include respond-above(xxl) {
    padding: 100px 120px 0px;
  }
}


.whyGalapagos__title {
  @include fontMDNichrome;
  text-transform: uppercase;
  padding-bottom: 20px;
  border-bottom: 1px solid #FFF;
  margin: 35px 0;
  font-size: 58px;
  line-height: 46px;
  width: 100%;

  @include respond-above(s) {
    margin: 35px 0;
    width: 50%;
  }

  @include respond-above(m_plus) {
    font-size: 98px;
    line-height: 76px;
    width: 60%;
    margin: 0 0 60px;
    padding-bottom: 60px;
  }
}

// BLOCK

.whyGalapagos__block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include respond-above(m_plus) {
    flex-direction: row;
  }
}

.whyGalapagos__img {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 35px;
  border-bottom: 1px solid #FFF;
  margin: 0 0 35px;

  @include respond-above(s) {
    margin: 0 0 35px;
    width: 50%;
  }

  @include respond-above(m_plus) {
    padding: 0 50px;
    border-bottom: none;
    margin: 0;
    width: 47%;
  }
}

.whyGalapagos__txt {
  @include fontOscarLight;
  font-size: 14px;
  line-height: 16px;
  padding-bottom: 25px;
  width: 50%;

  @include respond-above(m_plus) {
    padding-bottom: 0;
    font-size: 16px;
    line-height: 20px;
    width: 13%;
  }
}

.whyGalapagos__desc {
  @include fontOscarLight;
  font-size: 16px;
  line-height: 18px;
  width: 100%;

  @include respond-above(m_plus) {
    padding: 0 50px;
    font-size: 24px;
    line-height: 29px;
    width: 40%;
  }
}

