.ourTeam {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  visibility: hidden;
  transition: all ease-in-out 1s;

  .pp-section.active &{
    visibility: visible;

  }
}

.ourTeam__siure {
  display: block;
  position: absolute;
  object-fit: cover;
  object-position: left;
  height: 128px;
  width: auto;
  margin-right: 50px;
  @include respond-above(s) {
    position: relative;
    object-fit: cover;
    object-position: left;
    height: 110%;

  }
  @include respond-above(m_plus) {
  }
}

// INFO

.ourTeam__wrapper {
  display:flex;
  flex-direction: column;
  position: relative;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 70px 15px 150px;
  @include respond-above(m_plus){
    flex-direction: row;
    padding: 150px 80px 50px 150px;
    }
  @include respond-above(l){
    padding: 150px 150px 100px 230px;
  }
  @include respond-iphone{
    padding: 80px 20px 150px;
  }
}

.ourTeam__info {
  display: block;
  width: 100%;
  z-index: 1;
  text-align: left;

  @include respond-above(m_plus) {
    width: 65%;
  }
}

.person {
  display: flex;
  position: relative;
  height: 50%;
  padding-bottom: 15px;
  @include respond-above(s) {
    padding-bottom: 50px;
  }
  
  @include respond-above(m_plus) {
    
  }
}
.person__descriptionWrapper{
  display: block;
  position: relative;
  //padding-right: 100px;
}

.person__titleWrapper{
  position: relative;
  display: block;
  padding: 10px 20px 0 0;
  margin-left: 120px;
  height: 128px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top:0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
}
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom:1px;
    width: 100%;
    height: 1px;
    background-color: $black;
  }

  @include respond-above(s) {
    margin-left: 0;
    padding: 10px 0;
    margin-bottom: 15px;
    height: auto;
    
  }
  
  @include respond-above(m_plus) {
    
  }
  


}
.person__blockNameLink {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;

  @include respond-above(m_plus) {
    
  }

}

.person__name {
  @include fontOscarBold;
  font-size: 21px;
  line-height: 21px;
  color: $black;
  white-space: nowrap;
  margin-top: 40px;

  @include respond-above(s) {
    font-size: 24px;
    line-height: 24px;
    margin-top: auto;
  }

  @include respond-above(m_plus) {
    font-size: 32px;
    line-height: 24px;
    width: calc(100% - 40px);
    padding-right: 12px;
  }
}

.person__skills {
  @include fontOscarRegular;
  font-size: 16px;
  line-height: 16px;
  color: $black;

  @include respond-above(s) {
    font-size: 15px;
    line-height: 15px;
  }

  @include respond-above(m_plus) {
    font-size: 26px;
    line-height: 26px;
    
  }
}

.person__description {
  display: none;
  @include fontOscarRegular;
  color: $black;

  @include respond-above(s) {
    display: block;
    font-size: 16px;
    line-height: 18px;
  }

  @include respond-above(m_plus) {
    font-size: 2.3vh;
    line-height: 2.3vh;
  }
}

.person__description-mobile{
  display: block;
  font-size: 14px;
  line-height: 14px;
  padding-top: 10px;

  @include respond-iphone;
  font-size: 14px;
  line-height: 14px;

  @include respond-above(s) {
    display: none;
  }
}

.person__link {
  position: absolute;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include respond-above(m_plus) {
    position: relative;
    right: 0;
    top: auto;
    width: 100%;
  }
}

.person__imgLink {
  width: 30px;

  @include respond-above(m_plus) {
    width: 23px;
  }
}

.ourTeam__network{
  display: flex;
  position: relative;
  width: 100%;
  @include respond-above(m_plus) {
    justify-content: end;
    width: 35%;
    right: 0;
    bottom: 0;
    
  }

}

.ourTeam__networkWrapper{
  display: flex;
  position: relative;
  width: 100%;
  z-index: 1;
  flex-direction: row;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  
  @include respond-above(m_plus) {
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 70%;
  }

}

.ourTeam__infoNetworkWrapper{
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 80px;
  
  @include respond-above(m_plus){
    flex-direction: column;
    height: 150px;
    align-items: flex-end;
    justify-content: space-evenly;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top:0px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $black;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom:1px;
    width: 100%;
    height: 1px;
    background-color: $black;
  }
}

.ourTeam__pngNetwork{
      height: 40px;
      width: fit-content;
      max-width: none;
    

    @include respond-above(s) {
    
    }
  
    @include respond-above(m_plus) {
      height: 180px;
      width: fit-content;
      max-width: none;
      margin-bottom: 20px;
      
    }

    @include respond-iphone;
    width: auto;
    
}

.ourTeam__networkTitle{
  @include fontOscarRegular;
  color: $black;
  display: none;

  @include respond-above(s) {
    display: block;
    font-size: 15px;
    line-height: 15px;
  }

  @include respond-above(m_plus) {
    font-size: 32px;
    line-height: 24px;
    
  }
}
.ourTeam__networkTitle-mobile{
  @include fontOscarRegular;
  font-size: 24px;
  line-height: 21px;
  color: $black;
  padding-right: 20px;
  @include respond-above(s) {
    display: none;
  }

}

.ourTeam__networkText{
  @include fontOscarRegular;
  font-size: 14px;
  line-height: 14px;
  color: $black;
  

  @include respond-above(s) {
    font-size: 15px;
    line-height: 15px;
  }

  @include respond-above(m_plus) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 10px;
  }
}

