.svgLogoName {
  display: block;

  path {
    transition: $transition-ease-in-out;

    .header--white & {
      fill: $white;
    }

    .header--black & {
      fill: $black;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    transition: $transition-ease-in-out;

    .header__logoBig & {
      height: 80px;
    }

    .header__logoSmall & {
      width: 40px;
      height: 40px;
    }
  }
}
