.svgArrow {

  height: 100%;

  path {
    transition: $transition-ease-in-out;

    .header--white & {
      fill: $white;
    }

    .header--black & {
      fill: $black;
    }
  }

  svg {
    transition: $transition-ease-in-out;

    width: 55px;
    height: 100%;
  }

}
