.howWeWork {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  .pp-section.active &{
    opacity: 100%;

  }
}

.howWeWork__wrapper{
  display:block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 120px 60px;
  @include respond-above(m_plus){
    padding: 150px 70px 50px 125px;
    }
  @include respond-above(l){
    padding: 150px 120px 50px 200px;
    }
  @include respond-iphone;
  padding: 120px 60px;

}

.howWeWork__slide{
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @include respond-above(m_plus){
   align-items: flex-start;
  }
}
.howWeWork__png{
  height: 130px;
  width: fit-content;
  max-width: none;
  &.howWeWork__png--rond{
    padding: 10px 0;
  }
  @include respond-iphone;
  width: auto;
  
}

.howWeWork__titleWrapper{
  position: relative;
  width: 100%;
  height: 120px;
  margin: 20px 0;
  @include respond-above(m_plus){
    height: 159px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top:1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom:1px;
    width: 100%;
    height: 1px;
    background-color: black;
  }

}
.howWeWork__title{
  @include fontMDNichrome;
  text-transform: uppercase;
  font-size:35px;
  line-height: 30px;
  text-align: center;
  margin: 10px 0 10px;
  @include respond-above(m_plus){
    font-size:42px;
    line-height: 38px;
    text-align: start;
    margin: 20px 0 10px;
    }
}

.howWeWork__text{
  @include fontOscarRegular;
  font-size:16px;
  line-height: 18px;
  text-align: center;
  @include respond-above(m_plus){
  text-align: start;
  font-size:19px;
  line-height: 19px;
  padding-right: 30px;
  }
  @include respond-above (l){
    font-size:21px;
    line-height: 24px;
    padding-right: 80px;

  }

}
.howWeWork{
  .splide__arrow{
    display: block;
    @include respond-above(m_plus){
      display:none;
    }
  }
  .splide__pagination{
    display: none;
  }
  .splide__arrow--prev {
    left: -2em;
}
  .splide__arrow--next {
    right: -2em;
  }
}

