*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  &.blockScroll {
    overflow: hidden;
  }
}

body {
  min-height: 100vh;
  @include hidden-scrollbar;
}

section {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
}