.ourWhy {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  .pp-section.active &{
    opacity: 100%;

  }
}

.ourWhy__wrapper{
  display:flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding: 80px 20px;
  @include respond-above(m_plus){
    flex-direction: row;
    padding: 150px;
    }
  @include respond-above(l){
    padding: 150px 150px 150px 200px;
  }
  @include respond-iphone{
    padding: 80px 20px 150px;
  }
}

.ourWhy__boxTitle {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  width: 100%;
  height: 50%;
  @include respond-above(m_plus){
    justify-content: flex-start;
    align-items: flex-end;
    width: 60%;
    height: 100%;
    }
}

.ourWhy__boxDescription {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content:center;
  align-items:flex-start;
  text-align: start;
  height: 50%;
  width: 100%;
  @include respond-above(m_plus){
    justify-content: space-between;
    width: 40%;
    height: fit-content;
    padding-left: 20px;
    }
  &::before {
    content: "";
    display: none;
    position: absolute;
    left: 0;
    width: 1px;
    background-color: black;
    height: 100%;
    @include respond-above(m_plus){
      display: block;
      position: absolute;
    }
  }

}

.ourWhy__title {
  @include fontMDNichrome;
  text-transform: uppercase;
  color: $turquese;
  font-size: 67px;
  line-height: 57px;
  letter-spacing: -1px;
  @include respond-above(m_plus){
    letter-spacing: 0;
    font-size: 8vw;
    line-height: 7vw;
  }

}

.ourWhy__descriptionTitle {
  @include fontOscarRegular;
  font-size: 24px;
  line-height: 24px;
  margin-top: 20px;
  @include respond-above(m_plus){
    margin-top: 0;
    font-size: 2vw;
  line-height: 2vw;
  }
}

.ourWhy__descriptionText {
  @include fontOscarRegular;
  font-size: 16px;
  line-height: 16px;
  margin-top: 20px;
  @include respond-above(m_plus){
    margin-top: 30px;
    font-size: 1.5vw;
    line-height: 1.7vw;
  }
}
