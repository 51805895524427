@font-face {
    font-family: "FHOscar-Bold";
    src: 
      url("../fonts/FH_Oscar/FHOscar-Bold?#iefix") format("embedded-opentype"),
      url("../fonts/FH_Oscar/FHOscar-Bold.woff2") format("woff2"),
      url("../fonts/FH_Oscar/FHOscar-Bold.woff") format("woff"),
      url("../fonts/FH_Oscar/FHOscar-Bold.ttf") format("truetype");
    font-display: swap;
  }
  
  @font-face {
    font-family: "FHOscar-BoldItalic";
    src: 
      url("../fonts/FH_Oscar/FHOscar-BoldItalic?#iefix") format("embedded-opentype"),
      url("../fonts/FH_Oscar/FHOscar-BoldItalic.woff2") format("woff2"),
      url("../fonts/FH_Oscar/FHOscar-BoldItalic.woff") format("woff"),
      url("../fonts/FH_Oscar/FHOscar-BoldItalic.ttf") format("truetype");
    font-display: swap;
  }
  
  @font-face {
    font-family: "FHOscar-Light";
    src: 
      url("../fonts/FH_Oscar/FHOscar-Light?#iefix") format("embedded-opentype"),
      url("../fonts/FH_Oscar/FHOscar-Light.woff2") format("woff2"),
      url("../fonts/FH_Oscar/FHOscar-Light.woff") format("woff"),
      url("../fonts/FH_Oscar/FHOscar-Light.ttf") format("truetype");
    font-display: swap;
  }
  
  @font-face {
    font-family: "FHOscar-LightItalic";
    src: 
      url("../fonts/FH_Oscar/FHOscar-LightItalic?#iefix") format("embedded-opentype"),
      url("../fonts/FH_Oscar/FHOscar-LightItalic.woff2") format("woff2"),
      url("../fonts/FH_Oscar/FHOscar-LightItalic.woff") format("woff"),
      url("../fonts/FH_Oscar/FHOscar-LightItalic.ttf") format("truetype");
    font-display: swap;
  }
  
  @font-face {
    font-family: "FHOscar-Regular";
    src: 
      url("../fonts/FH_Oscar/FHOscar-Regular?#iefix") format("embedded-opentype"),
      url("../fonts/FH_Oscar/FHOscar-Regular.woff2") format("woff2"),
      url("../fonts/FH_Oscar/FHOscar-Regular.woff") format("woff"),
      url("../fonts/FH_Oscar/FHOscar-Regular.ttf") format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "FHOscar-RegularItalic";
    src: 
      url("../fonts/FH_Oscar/FHOscar-RegularItalic?#iefix") format("embedded-opentype"),
      url("../fonts/FH_Oscar/FHOscar-RegularItalic.woff2") format("woff2"),
      url("../fonts/FH_Oscar/FHOscar-RegularItalic.woff") format("woff"),
      url("../fonts/FH_Oscar/FHOscar-RegularItalic.ttf") format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "MDNichrome";
    src: 
      url("../fonts/MDNichrome/MDNichrome-Dark?#iefix") format("embedded-opentype"),
      url("../fonts/MDNichrome/MDNichrome-Dark.woff2") format("woff2"),
      url("../fonts/MDNichrome/MDNichrome-Dark.woff") format("woff"),
      url("../fonts/MDNichrome/MDNichrome-Dark.ttf") format("truetype");
    font-display: swap;
  }
  
  // FONT VARIABLES
  
  $OscarBold: "FHOscar-Bold", Arial, sans-serif;
  
  $OscarBoldIta: "FHOscar-BoldItalic", Arial, sans-serif;
  
  $OscarLight: "FHOscar-Light", Arial, sans-serif;
  
  $OscarLightIta: "FHOscar-LightItalic", Arial, sans-serif;
  
  $OscarRegular: "FHOscar-Regular", Arial, sans-serif;

  $OscarRegularIta: "FHOscar-RegularItalic", Arial, sans-serif;

  $MDNichrome: "MDNichrome", Arial, sans-serif;
  
  // FONTS
  
  @mixin fontOscarBold {
    font-family: $OscarBold;
    font-style: normal;
  }
  
  @mixin fontOscarBoldIta {
    font-family: $OscarBoldIta;
    font-style: normal;
  }
  
  @mixin fontOscarLight {
    font-family: $OscarLight;
    font-style: normal;
  }
  
  @mixin fontOscarLightIta {
    font-family: $OscarLightIta;
    font-style: normal;
  }
  
  @mixin fontOscarRegular {
    font-family: $OscarRegular;
    font-style: normal;
  }

  @mixin fontOscarRegularIta {
    font-family: $OscarRegularIta;
    font-style: normal;
  }
  
  @mixin fontMDNichrome {
    font-family: $MDNichrome;
    font-style: normal;
  }
  