.footer__content {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: $overlay-white;
    padding: 40px 0px 30px;
    transform: translateX(-100%);

    @include respond-above(s) {
        padding: 40px;
    }

    @include respond-above(m) {
        transform: translateX(0);
        position: relative;
        display: block;
        height: auto;
        background-color: transparent;
        padding: 60px 80px;
    }

    .footer--open &{
    transform: translateX(0);

    }
}



.footer__content--black {
    padding: 60px 40px;

    * {
        color: $black;
    }
}

.footer__content--white {

    * {
        color: $white;
    }
}

.footer__separator {
    width: 100%;
    height: 1px;
    display: none;
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;

    @include respond-above(s) {
        margin-bottom: 40px;
        margin-top: auto;
    }

    @include respond-above(m) {
        display: block;
        margin-bottom: 20px;
    }

    .footer__content--black & {
        background: $black;
    }

    .footer__content--white & {
        background: $white;
    }
}

.footer__block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column-reverse;

    @include respond-above(m) {
        justify-content: space-between;
        flex-direction: row;
    }
}

.footer__block__left {
    display: block;
    position: relative;
    text-align: left;
}

.footer__block__right {
    display: block;
    position: relative;
    text-align: left;
    margin-bottom: 12px;

    @include respond-above(m) {
        text-align: right;
        margin-bottom: 0;
    }
    @include respond-iphone{
        margin-bottom: 15px;
    }
}

.footer__block__left--firts{
    & .footer__text {
        @include fontOscarRegular;
        font-size: 12px;
        margin-bottom: 0;
    
        @include respond-above(m) {
            font-size: 15px;
        }
        @include respond-iphone{
            font-size: 15px;
        }
    }
}

.footer__text {
    @include fontOscarRegular;
    font-size: 12px;
    margin-bottom: 4px;

    @include respond-above(m) {
        font-size: 18px;
    }
    @include respond-iphone{
        font-size: 15px;
        margin-bottom: 7px;
    }
}

.footer__block__link {
    display: flex;
    justify-content: flex-start;

    @include respond-above(m) {
        justify-content: flex-end;
    }
}

.footer__link {
    @include fontOscarRegular;
    font-size: 16px;
    margin-bottom: 4px;
    cursor: pointer;

    @include respond-above(m) {
        font-size: 18px;
    }
    @include respond-iphone{
        font-size: 18px;
        margin-bottom: 7px;
    }
}

.footer__block__center{
    @include fontOscarRegular;
    display: block;
    margin-bottom: 4px;

    @include respond-above(m) {
        display: block;
        font-size: 18px;
    }

    @include respond-iphone{
        font-size: 18px;
        margin-bottom: 7px;
    }

}

.footer__svg{
    position: relative;
    width: 20px;
    margin: 0 10px;
}

.footer__svg--white{
    display: none;
    .footer__content--white &{
        display: block;
    }
}

.footer__svg--black{
    display: none;
    .footer__content--black &{
        display: block;
    }
}

.footer__button{
    display: block;
    position: fixed;
    top: 625px;
    left: 35px;
    z-index: 99;
    @include respond-above(m) {
        display: none;
    } 
}