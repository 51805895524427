.whoWeAre {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  .pp-section.active &{
    opacity: 100%;

  }
}
.whoWeAre__wrapper{
  display:flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 60px 20px;
  @include respond-above(m_plus){
    flex-direction: row;
    padding: 150px 80px 50px 150px;
    }
  @include respond-above(l){
    padding: 150px 200px 50px 200px;
  }
  @include respond-iphone{
    padding: 80px 20px 150px;
  }
}


.whoWeAre__boxTitle {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  text-align: start;
  width: 100%;
  height: 40%;
  @include respond-above(m_plus){
    width: 50%;
    height: 100%;
    }
}

.whoWeAre__descriptionWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items:flex-start;
  text-align: start;
  height: 60%;
  width: 100%;
  padding-bottom: 10px;
  @include respond-above(m_plus){
    padding-bottom: 0;
    width: 50%;
    height: 100%;
    }

}

.whoWeAre__descriptionElement {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  text-align: start;
  height: 33.33%;
  width: 100%;
  margin-top: 15px;
  @include respond-above(m_plus){
    margin-top: 30px;
    }
  
}

.whoWeAre__title {
  @include fontMDNichrome;
  text-transform: uppercase;
  color: $white;
  font-size: 58px;
  line-height: 54px;
  @include respond-above(m_plus){
    font-size: 9vw;
    line-height: 8vw;
    }

}

.whoWeAre__descriptionTitle {
  width: 30%;
  margin-right: 20px;
  @include fontOscarRegular;
  text-transform: uppercase;
  text-align: start;
  font-size: 18px;
  line-height: 18px;
  @include respond-above(m_plus){
    text-align: end;
    font-size: 36px;
    line-height: 36px;
    }
  
}

.whoWeAre__descriptionText {
  width: 70%;
  color: $white;
  @include fontOscarRegular;
  font-size: 15px;
  line-height: 16px;
  @include respond-above(m_plus){
    font-size: 21px;
    line-height: 27px;
    }
  @include respond-above(xl){
    padding-right: 40px;
  }
}
