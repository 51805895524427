.whatWeOffer {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  .pp-section.active &{
    opacity: 100%;

  }
}

.whatWeOffer__wrapper{
  display:block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 100px 60px;
  @include respond-above(m_plus){
    padding: 150px 70px 50px 125px;
    }
  @include respond-above(l){
    padding: 150px 120px 50px 200px;
    }
  @include respond-iphone;
  padding: 100px 60px;

}

.whatWeOffer__slide{
  display:flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  @include respond-above(m_plus){
   align-items: flex-start;
  }
}
.whatWeOffer__pngDesktop{
  display: none;
  height: 100px;
  width: fit-content;
  max-width: none;
  &.whatWeOffer__png--rond{
    padding: 10px 0;
  }
  @include respond-above(s){
    display: block;
  }
  @include respond-iphone;
  width: auto;
  
}

.whatWeOffer__pngMobile{
  display: block;
  height: 65px;
  width: fit-content;
  max-width: none;
  &.whatWeOffer__png--rond{
    padding: 10px 0;
  }
  @include respond-iphone;
  width: auto;
  @include respond-above(s){
    display: none;
  }

}

.whatWeOffer__titleWrapper{
  position: relative;
  width: 100%;
  height: 63px;
  margin: 10px 0;
  @include respond-above(m_plus){
    height: 100px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top:1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $white;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom:1px;
    width: 100%;
    height: 1px;
    background-color: $white;
  }

}
.whatWeOffer__title{
  @include fontMDNichrome;
  color: $white;
  text-transform: uppercase;
  font-size:20px;
  line-height: 20px;
  text-align: left;
  margin: 10px 0 10px;
  @include respond-above(m_plus){
    font-size:28px;
    line-height: 28px;
    text-align: start;
    margin: 20px 0 20px;
    }
}

.whatWeOffer__text{
  @include fontOscarRegular;
  color: $white;
  font-size:16px;
  line-height: 18px;
  text-align: start;
  margin-bottom: 10px;
  @include respond-above(m_plus){
  text-align: start;
  font-size:16px;
  line-height: 21px;
  padding-right: 30px;
  margin-bottom: 5px;
  }
  @include respond-above (l){
    font-size:16px;
    line-height: 21px;
    padding-right: 80px;
    margin-bottom: 5px;

  }

}

.whatWeOffer__text--list{
  padding: 0 20px;
}


.whatWeOffer{
  .splide__arrow{
    display: block;
    @include respond-above(m_plus){
      display:none;
    }
  }
  .splide__pagination{
    display: none;
  }
  .splide__arrow--prev {
    left: -2em;
}
  .splide__arrow--next {
    right: -2em;
  }
}

.whatWeOffer__list{
  list-style: disc;
  color: $white;
  margin-bottom: 10px;
  
}

