.withWhom {
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: all ease-in-out 0.5s;
  .pp-section.active &{
    opacity: 100%;

  }
}

.withWhom__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

// BLOCK ORANGE

.withWhom__orange {
  background-color: $orange;
  width: 100%;
  height: 45%;
  padding: 55px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;


  @include respond-above(m_plus) {
    justify-content: flex-start;
    flex-direction: row;
    padding: 60px 140px 20px;
    height: 50%;
  }
  @include respond-iphone{
    height: 40%;
    padding: 55px 20px 20px;
  }
}

.withWhom__orangeText {
  width: 100%;
  margin-bottom: 10px;

  @include respond-above(m_plus) {
    margin-bottom: 0px;
    width: 50%;
  }
}

.withWhom__text {
  width: 100%;
  text-align: start;
  @include fontMDNichrome;
  color: $white;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 40px;

  @include respond-above(m_plus) {
    width: 70%;
    text-align: left;
    font-size: 7vw;
    line-height: 6vw;
  }
}

.withWhom__orangeInfo {
  width: 100%;
  display: flex;
  justify-content: center;

  @include respond-above(m_plus) {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    width: 50%;
  }
}

.withWhom__info {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include respond-above(m_plus) {
    align-items: flex-start;
    justify-content: center;
  }
}

.withWhom__svg {
  width: 50px;
  margin-bottom: 12px;

  @include respond-above(m_plus) {
    width: 100px;
  }
}

.withWhom__infoText {
  @include fontOscarLight;
  color: #FFF;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  width: 70%;

  @include respond-above(m_plus) {
    font-size: 21px;
    line-height: 24px;
    text-align: left;
  }
}

// BLOCK WHITE

.withWhom__white {
  background-color: #FFF;
  width: 100%;
  height: 55%;
  padding: 5px 20px 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  @include respond-above(m_plus) {
    padding: 60px 140px;
    height: 50%;
  }
  @include respond-iphone{
    height: 60%;
    padding: 20px 20px 120px;
  }
}

.withWhom__logoText {
  text-align: left;
  @include fontOscarLight;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;

  @include respond-above(m_plus) {
    font-size: 27px;
    line-height: 32px;
  }
}

.withWhom__blockLogo {
  display: block;
  width: 50%;

  @include respond-above(m_plus) {
    width: 25%;
 }
}

.withWhom__logo {
  width: 70px;

  @include respond-above(m_plus) {
     width: 120px;
  }
}
